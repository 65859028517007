<template>
  <div class="app-container">
    <div class = "content">
      <div class = "item">
        <div class = "tit">协议名称：</div>
        <div class = "ct">{{form.name}}</div>
      </div>
      <div class = "item">
        <div class = "tit">协议正文：</div>
        <div  v-html="form.content"></div>
      </div>
    </div>
  </div>
</template>

<script>

import request from '@/utils/request'
export default {
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 10,
        start_datetime: '',
        end_datetime: '',
        is_reward: '',
        is_resolved: ''
      },
      form: {},
      total: null,
      listLoading: true,
      btnLoading: false,
      dialogFormVisible: false
    }
  },
  created() {
    this.getItem()
  },
  methods: {
     getItem() {
      this.listLoading = true
      request({
        url: '/api/backend/agreement/info',
        method: 'get',
        params: {
          id: this.$route.query.id
        }
      }).then(response => {
        this.form = response.data
        this.listLoading = false
      })
    }


  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.content{
  width:1100px;
  margin:0 auto;
}
.item{
  width:100%;
  margin-top:20px;
  display: flex;
  .tit{
    width:150px;
    margin-right:20px;
    flex:0 0 auto;
  }
  .ct{

  }
  .img{
    display: block;
    flex:0 0 auto;
    width:250px;
    height:250px;
  }
}
</style>
